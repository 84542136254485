import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Autoplay, Mousewheel, Pagination, Navigation } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import Work from '../assets/images/Work.jpg'


export function Portfolio() {
    
  return (
    <div className='px-10'>
        <Swiper
            modules={[ EffectCoverflow, Autoplay, Mousewheel, Pagination, Navigation ]}
            effect={ 'coverflow' }
            initialSlide={ 3 }
            autoplay={{
                delay: 5000
            }}
            mousewheel={ true }
            pagination={{ clickable: true }}
            navigation={ true }
            spaceBetween={ 50 }
            zoom={ true }
            grabCursor={ true }
            centeredSlides={ true }
            slidesPerView={3}
            coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5
            }}
            className='hidden md:block'
        >
            <SwiperSlide>
                <img src={ Work } alt='' className='rounded-2xl hover:rounded-none hover:scale-105 duration-500' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={ Work } alt='' className='rounded-2xl hover:rounded-none hover:scale-105 duration-500' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={ Work } alt='' className='rounded-2xl hover:rounded-none hover:scale-105 duration-500' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={ Work } alt='' className='rounded-2xl hover:rounded-none hover:scale-105 duration-500' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={ Work } alt='' className='rounded-2xl hover:rounded-none hover:scale-105 duration-500' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={ Work } alt='' className='rounded-2xl hover:rounded-none hover:scale-105 duration-500' />
            </SwiperSlide>
        </Swiper>
        <Swiper 
            modules={[ EffectCoverflow, Autoplay, Mousewheel, Pagination, Navigation ]}
            effect={ 'coverflow' }
            initialSlide={ 3 }
            autoplay={{
                delay: 5000
            }}
            mousewheel={ true }
            pagination={{ clickable: true }}
            navigation={ true }
            spaceBetween={ 50 }
            zoom={ true }
            grabCursor={ true }
            centeredSlides={ true }
            slidesPerView={ 1 }
            coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5
            }}
            className='block md:hidden'
        >
            <SwiperSlide>
                <img src={ Work } alt='' className='rounded-2xl hover:rounded-none hover:scale-105 duration-500' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={ Work } alt='' className='rounded-2xl hover:rounded-none hover:scale-105 duration-500' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={ Work } alt='' className='rounded-2xl hover:rounded-none hover:scale-105 duration-500' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={ Work } alt='' className='rounded-2xl hover:rounded-none hover:scale-105 duration-500' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={ Work } alt='' className='rounded-2xl hover:rounded-none hover:scale-105 duration-500' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={ Work } alt='' className='rounded-2xl hover:rounded-none hover:scale-105 duration-500' />
            </SwiperSlide>
        </Swiper>
    </div>
  )
}
