import React, { useState } from 'react'
import Icon from '../assets/images/Icon.jpg'
import { MdMenu, MdClose } from 'react-icons/md'

export function Header() {

    // Boolean state handling the mobile menu open logic
    const [ mobileMenuOpen, setMobileMenuOpen ] = useState(true)

    const HeaderData = [
        {
            title: 'About Me',
            link: '#about'
        },
        {
            title: 'Portfolio',
            link: '#portfolio'
        },
        {
            title: 'Stacks',
            link: '#stacks'
        },
        {
            title: 'Blog',
            link: '#blog' 
        }
    ]


  return (
    <header className='bg-white md:border-b-[1px] shadow-md'>
        <nav className='flex justify-between mx-6 p-4'>
            <a href='/' className='flex flex-1 -m-1.5'>
                <img 
                    src={ Icon }
                    alt=''
                    width={70}
                    className='hidden md:block rounded-full hover:scale-95 transform duration-200 hover:opacity-80'
                />
            </a>
            <div className='hidden md:flex md:gap-x-12 p-4'>
                { HeaderData.map((value, key) => {
                    return (
                        <a href={  value.link } key={ key } className='opacity-50 inline-block hover:scale-110 duration-300 hover:opacity-100 hover:font-serif font-semibold text-gray-900'>
                            <p>{ value.title }</p>
                        </a>
                    )
                })}
            </div>
            <div className='md:hidden fixed right-0 top-0 mt-4 p-4' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                <button>
                    <MdMenu
                        size={35}
                        className='rounded-lg hover:shadow-md opacity-50 hover:opacity-100 cursor-pointer'
                    />
                </button>

            </div>
            <div hidden={mobileMenuOpen} className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto ring-1 ring-slate-200 bg-gray-200 shadow-md md:hidden p-10'>
                <div className='flex justify-between'>
                    <div className='-mt-6 -ml-5 animate-fadeIn transition duration-100'>
                        <img 
                            src={ Icon }
                            alt=''
                            width={70}
                            className='rounded-full hover:scale-95 transform duration-200 hover:opacity-80'
                        />
                    </div>
                    <div className='fixed right-0 top-0 flex md:hidden mt-4 p-4' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                        <MdClose
                            size={35}
                            className='rounded-lg hover:shadow-md opacity-50 hover:opacity-100 cursor-pointer'
                        />                                                         
                    </div>
                </div>
                <div className='flex flex-col items-center mt-20 animate-fadeIn transition duration-100'>
                    { HeaderData.map((value, key) => {
                        return (
                            <a href={ value.link } key={ key } className='opacity-50 hover:scale-105 transform duration-200 hover:opacity-100 text-2xl font-semibold hover:font-serif text-gray-900'
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                <p className='py-4'>{ value.title }</p>
                            </a>
                        )
                    })}
                </div>
                <div className=''></div>
            </div>
        </nav>
    </header>
    )
}
