import React from 'react'
import { Header } from './components/Header'
import { Landing } from './components/Landing'
import { About } from './components/About'
import { Portfolio } from './components/Portfolio'
import { Grid } from './components/Grid/Grid'
import { Footer } from './components/Footer'
import { Blog } from './components/Blog'

function App() {
  return (
    <div className='h-screen'>
        <Header />
        <Landing />
        <h1 id='about' className='p-10 text-4xl font-bold hover:opacity-80 inline-block hover:scale-95 transform duration-200'>About Me</h1>
        <About />
        <h1 id='portfolio' className='p-10 text-4xl font-bold hover:opacity-80 inline-block hover:scale-95 transform duration-200'>Portfolio</h1>
        <Portfolio />
        <h1 id='stacks' className='p-10 text-4xl font-bold hover:opacity-80 inline-block hover:scale-95 transform duration-200'>Stacks</h1>
        <Grid />
        <h1 id='blog' className='p-10 text-4xl font-bold hover:opacity-80 inline-block hover:scale-95 transform duration-200'>Blog</h1>
        <Blog />
        <Footer />
    </div>
  );
}

export default App;
