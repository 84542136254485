import React from 'react'
import Icon from '../assets/images/Icon.jpg'
import { useInView } from 'react-intersection-observer'

export function About() {

    const { ref, inView } = useInView({
        rootMargin: '-50px',
        triggerOnce: true
    })

    const RELEVANT_SKILLS = [
        'UI/UX',
        'Cloud Architect',
        'Frontend Dev',
        'Backend Dev',
        'Data Analytics'
    ]
  return (
    <div ref={ref}>
        {
            inView && (
                <div className='rounded-xl w-auto hover:scale-105 duration-200 shadow-lg bg-gradient-to-bl from-indigo-500 mx-8 p-32 animate-fadeIn 2xl:flex'>
                    <div className='hidden 2xl:block mb-10 sm:p-28'>
                        <img src={Icon} alt='Icon' className='shadow-xl shadow-sky-300 w-auto max-w-full h-auto rounded-full hover:scale-95 transform duration-500 hover:opacity-80' />
                    </div>
                    <div className='md:bg-emerald-50 hover:scale-95 duration-500 md:shadow-xl lg:border-[1px] rounded-lg -ml-24 sm:-ml-10 xl:p-10 xl:ml-10'>
                        <div className='p-2'>
                            <h2 className='text-2xl font-serif font-semibold inline-block hover:opacity-80 hover:scale-95 duration-300 border-b-[1px] w-20'>Name</h2>
                            <p className='pl-10 p-1 font-medium'>AKITO HASEGAWA</p>
                        </div>

                        <div className='p-2'>
                            <h2 className='text-2xl font-serif font-semibold hover:opacity-80 hover:scale-95 duration-300 border-b-[1px] w-32'>Education</h2>
                            <ul className='pl-10 p-1 list-none sm:list-disc'>
                                <li className=''>
                                    <a href='https://www.monash.edu/' className='font-semibold hover:inline-block hover:scale-95 transform duration-200 hover:opacity-75 hover:border-b-[1px] border-b-black'>Monash University</a>,
                                    <a href='https://www.monash.edu/study/interest-areas/computer-science' className='pl-1 font-semibold hover:inline-block hover:scale-95 transform duration-200 hover:opacity-75 hover:border-b-[1px] border-b-black'>Bachelor of Computer Science</a>
                                </li>
                            </ul>
                            <p className='pl-10 font-thin p-1'>2021 - Present | GPA: 2.85</p>
                        </div>

                        <div className='p-2'>
                            <h2 className='text-2xl font-serif font-semibold hover:opacity-80 hover:scale-95 duration-300 border-b-[1px] w-40'>Experiences</h2>
                            <ul className='pl-10 p-1 list-none sm:list-disc'>
                                <li className=''>
                                    <a href='https://www.naxa.co.jp/' className='font-semibold hover:inline-block hover:scale-95 transform duration-200 hover:opacity-75 hover:border-b-[1px] border-b-black'>NAXA, Inc</a> : 2023, 6 months Intern | Fullstack dev
                                </li>
                            </ul>                                                        
                        </div>

                        <div className='p-2'>
                            <h2 className='text-2xl font-serif font-semibold hover:opacity-80 hover:scale-95 duration-300 border-b-[1px] w-48'>Relevant Skills</h2>
                            <div className='pl-10 p-2 inline-block lg:flex '>
                                { RELEVANT_SKILLS.map((value, key) => {
                                    return (
                                        <div key={ key } className='p-2'>
                                            <div className='p-1 hover:scale-125 transform duration-200 shadow-lg text-center hover:font-semibold rounded-full bg-gray-100 hover:opacity-80 border-[1px] border-black w-32'>{ value }</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className='p-2'>
                            <h2 className='text-2xl font-serif font-semibold hover:opacity-80 hover:scale-95 duration-300 border-b-[1px] w-32'>Interests</h2>
                            <ul className='pl-10 p-1 list-none sm:list-disc'>
                                <li className='font-semibold hover:opacity-80'>
                                    Cloud Engineering
                                </li>
                                <li className='font-semibold hover:opacity-80'>
                                    Machine Learning
                                </li>
                                <li className='font-semibold hover:opacity-80'>
                                    Artificial Intelligence
                                </li>
                                <li className='font-semibold hover:opacity-80'>
                                    Product Management
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
    </div>
  )
}
