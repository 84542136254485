import React from 'react'
import Image from '../assets/images/Landing.jpg'

export function Landing() {
  return (
    <div className='h-auto md:h-2/3 relative group/bg rounded-2xl shadow-2xl opacity-95 hover:opacity-100 bg-gradient-to-br from-purple-500 to-pink-500'>
        <div className="absolute top-0 -inset-full h-full w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-transparent to-white opacity-40 group-hover/bg:animate-shine" />
        <div className='lg:flex'>
            <div className='p-20'>
                <p className='font-extrabold pl-3 sm:pl-10 animate-fadeIn'>Hi, I'm AKITO HASEGAWA</p>
                <div className='group relative inline-block pt-6'>
                    <span className='font-extrabold text-4xl sm:text-6xl inline-block hover:scale-105 duration-500'>I'M A FRONT END DEVELOPER</span>
                    <span className='hidden lg:block rounded-xl h-2 absolute -bottom-5 left-0 w-0 bg-black transition-all duration-500 group-hover:w-full'></span>
                </div>
                <div className='font-mono font-semibold pt-10 pl-6'>
                    I design and develop <span className='border-b-[1px] border-black'>user experiences and happiness!</span> <br/>
                    I love coding and making our life for the better!<br />
                    I have working knowledge with 
                    <span className='pl-1 inline-block hover:scale-95 duration-300'>Figma</span>,
                    <span className='px-1 inline-block hover:scale-95 duration-300'>Photoshop</span>
                    for designing, <br/>
                    <span className='inline-block hover:scale-95 duration-300'>Typescript</span>, 
                    <span className='pl-1 inline-block hover:scale-95 duration-300'>React</span>,
                    <span className='pl-1 inline-block hover:scale-95 duration-300'>Nextjs</span>,
                    <span className='pl-1 inline-block hover:scale-95 duration-300'>Vue</span>,
                    <span className='pl-1 inline-block hover:scale-95 duration-300'>Nuxtjs</span>,
                    <span className='px-1 inline-block hover:scale-95 duration-300'>Tailwind</span>
                    for front end development, <br/>
                    <span className='inline-block hover:scale-95 duration-300'>Ruby on Rails</span>,
                    <span className='pl-1 inline-block hover:scale-95 duration-300'>Golang</span>,
                    <span className='pl-1 inline-block hover:scale-95 duration-300'>Nestjs</span>,
                    <span className='px-1 inline-block hover:scale-95 duration-300'>FastAPI</span>
                    for back end development
                </div>
                <div className='mt-10 sm:mt-0 p-6 sm:p-10 md:p-20'>
                    <a href='#about' className='inline-block hover:scale-95 shadow:xl hover:opacity-90 focus:scale-105 ease-in-out duration-300 focus:ring-1 ring-gray-200 rounded-full px-8 py-3 font-bold text-white bg-green-400 '>
                        Learn More
                    </a>
                </div>
            </div>
            <div className='hidden xl:block ml-10 mt-16'>
                <img src={ Image } alt='' className='shadow:lg rounded-full' width={500}/>
            </div>
        </div>
    </div>
  )
}
